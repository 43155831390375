import './App.css';
import { Route, Routes } from 'react-router-dom';
import LayoutSimple from './layouts/variations/LayoutSimple';

//VISTAS DE LANDING
import HomeView from './views/landing/HomeView';
import HomeViewEmployees from './views/landing/employees/HomeViewEmployees';
import QrView from './views/landing/qr/QrView';
// import HomeViewEmployeeOne from './views/landing/employees/HomeViewEmployeeOne';
import HomeViewCustomers from './views/landing/customers/HomeViewCustomers';
//import HomeViewCustomerOne from './views/landing/customers/HomeViewCustomerOne';
import HomeViewPrivacy from './views/landing/PrivacyView';

export default function App() {
  return (
    <Routes>     
      {/* RUTAS PARA FRONT CLIENTE */}
      <Route path="/" element={<LayoutSimple />}>
          <Route index element={<HomeView />} />
          <Route path="empleado" element={<HomeViewEmployees />} />

          <Route path="qr" element={<QrView />} />
          {/* <Route path="empleado/:id" element={<HomeViewEmployeeOne />} /> */}

          <Route path="cliente" element={<HomeViewCustomers />} />
          {/* <Route path="cliente/:id" element={<HomeViewCustomerOne />} /> */}

          <Route path="privacidad" element={<HomeViewPrivacy />} />
          
      </Route>
    </Routes>
  );
}
