import styled from "styled-components";

export default function EmployeeNotLoggedView ({ users }) {
    return (
        <StyledEmployeeNotLoggedView>
            <h3>Sesión Finalizada</h3>
            <div className="contenInit">
                {users.map((user) => (
                    <div key={user.CodUsr}>
                        <p>Por seguridad, por favor, volvé a loguearte</p>
                        <h2><strong>{user.NomUsr}</strong></h2>
                        <p>con el botón Verde "Empezar"</p>
                    </div>
                ))}
            </div>
        </StyledEmployeeNotLoggedView>
    );
};

const StyledEmployeeNotLoggedView = styled.div`
    .contenInit{
        background-color: #face55;
        width:85%;
        padding: 2rem 4rem;
        margin: 0 auto;
        border-radius: 1rem;
        margin-top: 4rem;
        text-align: center;
    }
    h2 {
        margin: 3rem 0rem;
    } 
    
`