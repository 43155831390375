import styled from "styled-components";

export default function EmployeeLoggedView ({ users, seller }) {
    return (
        <StyledEmployeeLoggedView>
            <div className="contenInit">
                {}
                
                {users.map((user) => (
                    <div key={user.CodUsr}>
                        <h2>Hola {seller === null ? '' : 'Vendedor'}</h2>
                        <h2><strong>{user.NomUsr} !!!</strong></h2>
                    </div>
                ))}
            </div>
        </StyledEmployeeLoggedView>
    );
};

const StyledEmployeeLoggedView = styled.div`
    .contenInit{
        background-color: #face55;
        width:85%;
        padding: 2rem;
        margin: 0 auto;
        border-radius: 1rem;
        margin-top: 4rem;
        text-align: center;
    }
    h2 {
        margin: 3rem 0rem;
    } 
    
`