import styled from "styled-components";

export default function HomeViewCustomers() {
    return (
        <StyledHomeViewCustomers>
            <div className='container'>
                <div className="row">
                    <div className="col-12">
                        <h2>Primer Home Cliente sin loguearse</h2>
                        <div id="carouselExample" class="carousel slide" style={{marginTop: '2rem'}}>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="imagen">Imagen de una novedad de Leiten</div>
                                </div>
                                <div className="carousel-item">
                                    <div className="imagen">Imagen de una novedad de Leiten</div>
                                </div>
                                <div className="carousel-item">
                                    <div className="imagen">Imagen de una novedad de Leiten</div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>

                    </div>
                    <div className="col-12 contenInit">
                        <h4 style={{marginTop: '1.5rem'}}>Por favor Iniciar sesion</h4>
                        <div style={{marginTop: '0rem'}}>
                            <button className="btn buttonCuarto">Ingresar</button>
                        </div>
                    </div>
                </div>
            </div>
        </StyledHomeViewCustomers>
    )
};

const StyledHomeViewCustomers = styled.div`
    background-color: var(--colorPrimario);
    height: auto;
    .contenInit{
        background-color: #face55;
        width:85%;
        margin: 0 auto;
        border-radius: 1rem;
        margin-top: 2rem;
        margin-bottom: 5rem;
        text-align: center;
    }
    .containerNovedades{
        width: 100%;
    }
    .imagen{
        color: #fff;
        background-color: var(--colorCuarto);
        width: 100%;
        height: 300px;
        display:flex;
    justify-content: center;
    align-items: center;
    }
    button{
        margin: 3rem;
    
    }
`