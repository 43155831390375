import styled from "styled-components"

export default function HomeView() {

    return (
        <StyleHomeView>
            <div className="container " style={{textAlign: 'center'}}>
                        <h2>Bienvenio a la APP de Leiten</h2>
                <div className="row">
                    <div className="col-12 contenInit">
                        <br />
                        <h4>Por favor Iniciar sesión</h4>
                        <p>presioná el botón verde "Empezar"</p>
                    </div>
                </div>
            </div>
        </StyleHomeView>
    )
};

const StyleHomeView = styled.div`
    .contenInit{
        background-color: #face55;
        width:85%;
        margin: 0 auto;
        border-radius: 1rem;
        margin-top: 3rem;
        padding: 3rem 3rem;
        text-align: center;
    },
    h2{
        margin-top: 3rem;
    }
    p {
        margin-top: 3rem;
    }
`