import { useSearchParams } from "react-router-dom";
import styled from "styled-components";


export default function QrView(){

    const [searchParams] = useSearchParams();
    
    const idEtiParams = searchParams.get ('ideti');
    const idTestParams = searchParams.get ('idtest');
    const idProdParams = searchParams.get ('idprod');
    
    return(
        <StyledQrView>
            <div className="contenInit">
                <h3 style={{textAlign:'center'}}>Escaneaste el QR: </h3>
                <br />
                <h2 style={{textAlign:'center'}}>
                    {idTestParams !== null ? `La Etiqueta llego desde Testing y es: ${idTestParams}` : idProdParams === null ? `La Etiqueta: ${idEtiParams}` : `El Producto: ${idProdParams}`}
                </h2>

            </div>
        </StyledQrView>
    )
}

const StyledQrView = styled.div`
    .contenInit{
        background-color: #face55;
        width:85%;
        padding: 2rem 4rem;
        margin: 0 auto;
        border-radius: 1rem;
        margin-top: 4rem;
    }
    
`