import styled from 'styled-components';

export default function Spinner(){
    return(
        <div className="containerApp">
            <StyledSpinner>
                    <div className="containerSpinner" style={{textAlign: 'center'}}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                        <p>cargando...</p>
                    </div>
            </StyledSpinner>
        </div>
    )
}

const StyledSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10rem;
`